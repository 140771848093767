@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
	min-height: 100%;
}

@font-face {
	font-family: 'Mabry Pro';
	src: url('../../public/fonts/Mabry\ Pro\ Bold.otf');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Mabry Pro';
	src: url('../../public/fonts/Mabry\ Pro\ Black\ Italic.otf');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Mabry Pro';
	src: url('../../public/fonts/Mabry\ Pro\ Black.otf');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Mabry Pro';
	src: url('../../public/fonts/Mabry\ Pro.otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Mabry Pro';
	src: url('../../public/fonts/Mabry\ Pro\ Light.otf');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Mabry Pro';
	src: url('../../public/fonts/Mabry\ Pro\ Light\ Italic.otf');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Mabry Pro';
	src: url('../../public/fonts/Mabry\ Pro\ Medium.otf');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Mabry Pro';
	src: url('../../public/fonts/Mabry\ Pro\ Medium\ Italic.otf');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Mabry Pro';
	src: url('../../public/fonts/Mabry\ Pro\ Bold\ Italic.otf');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Mabry Pro';
	src: url('../../public/fonts/Mabry\ Pro\ Black\ Italic.otf');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Mabry Pro';
	src: url('../../public/fonts/Mabry\ Pro\ Black.otf');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Mabry Pro';
	src: url('../../public/fonts/Mabry\ Pro\ Italic.otf');
	font-weight: normal;
	font-style: italic;
}
